import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import Swal from "sweetalert2";

const InvestmentTable = () => {
  const [banca, setBanca] = useState(1000);
  const [data, setData] = useState([]);
  const [strategy, setStrategy] = useState("conservador");

  const handleStrategyChange = (e) => {
    setStrategy(e.target.value);
  };

  const handleCalcular = () => {
    const newData = [];
    let saldoAtual = parseFloat(banca);
    const startDate = new Date();

    for (let i = 0; i < 30; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);

      let meta;
      let stopLoss;
      switch (strategy) {
        case "conservador":
          meta = saldoAtual * 0.05;
          stopLoss = meta * 2;
          break;
        case "moderado":
          meta = saldoAtual * 0.1;
          stopLoss = meta * 2;
          break;
        case "agressivo":
          meta = saldoAtual * 0.15;
          stopLoss = meta * 2;
          break;
        default:
          meta = saldoAtual * 0.1;
          stopLoss = meta * 2;
      }

      const saldoFinal = saldoAtual + meta;

      newData.push({
        date: currentDate.toLocaleDateString(),
        saldoAtual: saldoAtual.toFixed(2),
        meta: meta.toFixed(2),
        stopLoss: stopLoss.toFixed(2),
        saldoFinal: saldoFinal.toFixed(2),
      });

      saldoAtual = saldoFinal;
    }

    setData(newData);
  };

  const handleDetalhes = () => {
    Swal.fire({
      title: "Detalhes da Gestão",
      html: `
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Data</th>
              <th>Saldo Atual</th>
              <th>Meta</th>
              <th>Stop Loss</th>
              <th>Saldo Final</th>
            </tr>
          </thead>
          <tbody>
            ${data.map((row, index) => `
              <tr key=${index}>
                <td style="font-size: 12px; padding: 0px;">${row.date.replace("/2024", "")}</td>
                <td style="font-size: 12px; padding: 0px;">R$${row.saldoAtual}</td>
                <td style="font-size: 12px; padding: 0px;">R$${row.meta}</td>
                <td style="font-size: 12px; padding: 0px;">R$${row.stopLoss}</td>
                <td style="font-size: 12px; padding: 0px;">R$${row.saldoFinal}</td>
              </tr>
            `).join("")}
          </tbody>
        </table>
      `,
      showCancelButton: false,
      confirmButtonText: "Fechar",
      customClass: {
        popup: "swal2-dark",
        confirmButton: "swal2-dark",
        htmlContainer: 'swal2-html-container'
      },
    });
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <h3 className="card-title">Gestão de Banca</h3>
          <hr className="divider divider-light divider-dashed" />
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col md="3">
          <label>Valor da Banca</label>
          <input
            type="number"
            className="form-control p-2"
            placeholder="Valor da banca"
            value={banca}
            onChange={(e) => setBanca(e.target.value)}
          />
        </Col>
        <Col md="3">
          <label>Perfil:</label>
          <select
            value={strategy}
            onChange={handleStrategyChange}
            className="form-select form-select-lg form-control"
          >
            <option value="conservador">Conservador</option>
            <option value="moderado">Moderado</option>
            <option value="agressivo">Agressivo</option>
          </select>
        </Col>
        <Col md="3">
          <Button className="btn btn-primary btn-sm" onClick={handleCalcular}>
            Calcular
          </Button>
        </Col>
        { data.length > 0 && (
            <Col md="3">
                <Button className="btn btn-secondary btn-sm" onClick={handleDetalhes}>
                    Detalhes
                </Button>
            </Col>
        )}
      </Row>
      <hr className="divider divider-light divider-dashed" />
      { data.length > 0 && (
        <Row>
            <Col md="12">
            <div style={{ height: "400px", overflowY: "auto" }}>
                <table className="table table-striped mt-4">
                <thead>
                    <tr>
                    <th>Data</th>
                    <th>Saldo Atual</th>
                    <th>Meta</th>
                    <th>Stop Loss</th>
                    <th >Saldo Final</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.date}</td>
                        <td>R${row.saldoAtual}</td>
                        <td>R${row.meta}</td>
                        <td>R${row.stopLoss}</td>
                        <td>R${row.saldoFinal}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            </Col>
        </Row>
      )}
    </div>
  );
};

export default InvestmentTable;